/**
 * This file was automatically generated, PLEASE DO NOT MODIFY IT BY HAND.
 */

export const HelmReleaseApiVersion = 'helm.toolkit.fluxcd.io/v2beta1';

export const HelmReleaseGVK = {
  apiVersion: 'v2beta1',
  group: 'helm.toolkit.fluxcd.io',
  plural: 'helmreleases',
};
