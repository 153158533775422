/**
 * This file was automatically generated, PLEASE DO NOT MODIFY IT BY HAND.
 */

export const ReleaseApiVersion = 'release.giantswarm.io/v1alpha1';

export const ReleaseGVK = {
  apiVersion: 'v1alpha1',
  group: 'release.giantswarm.io',
  plural: 'releases',
};
